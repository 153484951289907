import Cookies from "js-cookie"
import router, { resetRouter } from "@/router"
import {
  login,
  wwlogin,
  getInfo,
  logout,
  fslogin,
  fsloginMobile,
  qywxlogin,
  getQucikMenus,
} from "@/api/user"
import { getApplications } from "@/api/index2.0"
import Layout from "@/layout"

const MyTokenKey = "my_vue_admin_token"
const MyVersionKey = "my_vue_admin_version"
const MyServiceModeKey = "my_vue_admin_service_mode"

export function getToken() {
  return Cookies.get(MyTokenKey)
}

export function getVersion() {
  return Cookies.get(MyVersionKey)
}

export function removeToken() {
  return Cookies.remove(MyTokenKey)
}

export function setToken(token) {
  return Cookies.set(MyTokenKey, token, { expires: 5 })
}

export function setVersion(version) {
  return Cookies.set(MyVersionKey, version, { expires: 5 })
}

export function setServiceMode(serviceMode) {
  return Cookies.set(MyServiceModeKey, serviceMode, { expires: 5 })
}

let getinfo_data = null
let quick_menus_data = null
let applications_data = null

const state = {
  token: getToken(),
  avatar: "",
  name: "",
  tdl_view_type: "",
  first_route_path: "",
  routes: [],
  menus: [],
  quick_menus: [],
  search_applications: [],
  department: [],
  dynamic_auth: [],
  dist_timestamp: null,
  pcRouterConfig: null,
  serviceMode: "normal", // 商务业务模式：常规业务，签约业务
  role_identifys: "", // 用户拥有的角色以及对应角色的标识
  uid: "",
  pc_user_route: null,
  version: getVersion(),
  show_step: false,
  pc_show_step:false,
}

const mutations = {
  SET_SHOW_STEP:(state,show_step)=>{
    state.show_step = show_step
 },
 SET_PC_SHOW_STEP:(state,pc_show_step)=>{
  state.pc_show_step = pc_show_step
},

  SET_UID: (state, uid) => {
    state.uid = uid
  },
  SET_PC_USER_ROUTE: (state, route) => {
    state.pc_user_route = route
  },
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_VERSION: (state, version) => {
    state.version = version
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_TDL_VIEW_TYPE: (state, tdl_view_type) => {
    state.tdl_view_type = tdl_view_type
  },
  SET_ROUTES: (state, routes) => {
    state.routes = routes
  },
  SET_MENUS: (state, menus) => {
    state.menus = menus
  },
  SET_QUICK_MENUS: (state, quick_menus) => {
    state.quick_menus = quick_menus
  },
  SET_APPLICATIONS: (state, search_applications) => {
    state.search_applications = search_applications
  },
  SET_DEPARTMENT: (state, department) => {
    state.department = department
  },
  SET_FIRST_ROUTE_PATH: (state, first_route_path) => {
    state.first_route_path = first_route_path
  },
  SET_FIRST_DYNAMIC_AUTH: (state, dynamic_auth) => {
    state.dynamic_auth = dynamic_auth
  },
  SET_DIST_TIMESTAMP: (state, dist_timestamp) => {
    state.dist_timestamp = dist_timestamp
  },
  SET_PC_ROUTER_CONFIG: (state, pc_router_config) => {
    state.pcRouterConfig = pc_router_config
  },
  SET_SERVICE_MODE: (state, serviceMode) => {
    state.serviceMode = serviceMode
  },
  SET_ROLE_IDENTIFYS: (state, roleIdentifys) => {
    state.role_identifys = roleIdentifys
  },
}

const actions = {
  // 用户登录
  login({ commit }, userInfo) {
    console.log(userInfo)
    const { username, password } = userInfo

    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password })
        .then((response) => {
          const { data } = response
          commit("SET_TOKEN", data.token)
          setToken(data.token)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  wwlogin({ commit }, code) {
    console.log("code", code)
    return new Promise((resolve, reject) => {
      wwlogin({ code })
        .then((response) => {
          const { data } = response
          commit("SET_TOKEN", data.token)
          setToken(data.token)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fslogin({ commit }, code) {
    console.log("code", code)
    return new Promise((resolve, reject) => {
      fslogin({ code })
        .then((response) => {
          const { data } = response
          commit("SET_TOKEN", data.token)
          setToken(data.token)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  qywxlogin({ commit }, code) {
    console.log("qywx code", code)
    return new Promise((resolve, reject) => {
      qywxlogin({ code })
        .then((response) => {
          const { data } = response
          commit("SET_TOKEN", data.token)
          setToken(data.token)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  fsloginMobile({ commit }, code) {
    console.log("code", code)
    return new Promise((resolve, reject) => {
      fsloginMobile({ code })
        .then((response) => {
          const { data } = response
          commit("SET_TOKEN", data.token)
          setToken(data.token)
          resolve()
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (getinfo_data === null) {
        getInfo(state.token)
          .then((response) => {
            const { data } = response

            if (!data) {
              reject("校验失败，请重新登录.")
            }

            const {
              uid,
              routes,
              name,
              avatar,
              tdl_view_type,
              adm_menus,
              first_route_path,
              department,
              dynamic_auth,
              pcRouterConfig,
              identifys,
              pcUserRouter,
              show_step,
              pc_show_step
            } = data
            getinfo_data = data
            // roles must be a non-empty array
            if (!routes || routes.length <= 0) {
              commit("SET_TOKEN", "")
              commit("SET_ROUTES", [])
              removeToken()
              reject("用户还没有分配任何权限!")
            }

            // commit('SET_ROUTES', routes)
            commit("SET_UID", uid)
            commit("SET_PC_USER_ROUTE", pcUserRouter)
            commit("SET_NAME", name)
            commit("SET_AVATAR", avatar)
            commit("SET_TDL_VIEW_TYPE", tdl_view_type)
            commit("SET_MENUS", adm_menus)
            commit("SET_DEPARTMENT", department)
            commit("SET_FIRST_ROUTE_PATH", first_route_path)
            commit("SET_FIRST_DYNAMIC_AUTH", dynamic_auth)
            commit("SET_ROLE_IDENTIFYS", identifys)
            commit("SET_PC_ROUTER_CONFIG", pcRouterConfig)
            commit('SET_SHOW_STEP', show_step)
            commit('SET_PC_SHOW_STEP', pc_show_step)

            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        resolve(getinfo_data)
      }
    })
  },

  quickMenus({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (quick_menus_data === null) {
        getQucikMenus()
          .then((res) => {
            const { code, data, msg } = res

            if (!data || Number(code) != 0) {
              reject(msg || "校验失败，请重新登录.")
            }

            quick_menus_data = data
            commit("SET_QUICK_MENUS", quick_menus_data)

            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        resolve(quick_menus_data)
      }
    })
  },

  applications({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (applications_data === null) {
        getApplications({ platform: "pc" })
          .then((res) => {
            const { code, data, msg } = res

            if (!data || Number(code) != 0) {
              reject(msg || "校验失败，请重新登录.")
            }

            applications_data = data
            commit("SET_APPLICATIONS", applications_data)

            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      } else {
        resolve(applications_data)
      }
    })
  },

  setRoutes({ commit, state }, newroutes) {
    return new Promise((resolve, reject) => {
      commit("SET_ROUTES", newroutes)
      resolve()
    })
  },

  resetToken({ commit }) {
    return new Promise((resolve) => {
      commit("SET_TOKEN", "")
      commit("SET_ROUTES", [])
      removeToken()
      resolve()
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout()
        .then((response) => {
          commit("SET_TOKEN", "")
          commit("SET_ROUTES", [])
          removeToken()
          resetRouter()

          // reset visited views and cached views
          // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
          dispatch("tagsView/delAllViews", null, { root: true })

          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  setDistTimeStamp({ commit, state }, dist_timestamp) {
    return new Promise((resolve, reject) => {
      commit("SET_DIST_TIMESTAMP", dist_timestamp)
      resolve()
    })
  },

  setServiceMode({ commit, state }, serviceMode) {
    return new Promise((resolve, reject) => {
      if (serviceMode != "normal" && serviceMode != "sign") {
        serviceMode = "normal"
      }
      commit("SET_SERVICE_MODE", serviceMode)
      setServiceMode(serviceMode)
      resolve()
    })
  },
}

export function setUserGetInfoData(val = null) {
  getinfo_data = val
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
