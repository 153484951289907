<template>
    <div class="gui-box">
        <div class="gui-box-li">
            <img @click="skip()" class="gui-box-cha" :src="cha" />
            <!-- 首页引导 -->
            <div v-if="myStepNum == 1" class="backimg backimg-first">
                <div class="tiyan-title">首页<font>一</font><span>全面升级</span></div>
                <div class="gui-li-a gui-li-a-first">
                    <div class="gui-li-a-le">

                        <div class="tiyan-wenzi-a" :class="indexNumOn == index ? 'tiyan-wenzi-a-active' : ''"
                            v-for="item, index in indexData" :key="index" @mouseenter="handleMouseEnter(index)"
                            @mouseleave="handleMouseEnd()">
                            <div class="tiyan-wenzi-a-le"><img
                                    :src="indexNumOn == index ? item.topImged : item.topImg" /> </div>
                            <div class="tiyan-wenzi-a-ri">
                                <div>{{ item.title }}</div>
                                <div>{{ item.desc }}</div>
                            </div>
                        </div>

                        <div class="tiyan-but-di" style="float:left;margin-top:40px;margin-left:30px;">
                            <div class="tiyan-but-di-le" @click="skip()">跳过</div>
                            <div class="tiyan-but-di-min" @click="lastStep(1)">上一步</div>
                            <div class="tiyan-but-di-ri" @click="nextStep()">下一步</div>
                        </div>
                    </div>
                    <img :src="gui_a" class="gui-li-a-ri" />
                </div>
            </div>
            <!-- 业务中台引导 -->
            <div v-show="myStepNum == 2">
                <div class="gui-li-a">
                    <div class="yewuclass-le">
                        <div class="tiyan-titleed">
                            业务中台<font>一</font><span>全面升级</span>
                        </div>
                        <div class="yewuclass-le-lunbo">
                            <div class="swiper-container swiper-main-containered" ref="mySwiper">
                                <div class="content_container swiper-wrapper">
                                    <div class="commonApply swiper-slide" v-for="item, index in guideed" :key="index">
                                        <img class="common_item_icon" style="width:100%;height:100%;" :src="item.img" />
                                    </div>
                                </div>
                                <div class="pagination"></div>
                            </div>
                        </div>
                    </div>
                    <div class="yewuclass-ri">
                        <div class="yewuclass-ri-box">
                            <div @click="lunTap(index)" :class="myLunIndex == index ? 'lunActive' : ''"
                                class="yewuclass-ri-box-a" v-for="item, index in guideed" :key="index">
                                <img class="yewuclass-ri-a-img"
                                    :src="myLunIndex == index ? item.topImged : item.topImg" />
                                <p>{{ item.title }}</p>
                                <p>{{ item.desc }}</p>
                            </div>
                        </div>
                        <div class="tiyan-but-di" style="margin-top:56px;margin-left:35px;">
                            <div class="tiyan-but-di-le" @click="skip()">跳过</div>
                            <div class="tiyan-but-di-min" @click="lastStep()">上一步</div>
                            <div class="tiyan-but-di-ri" @click="nextStep()">下一步</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 业务中台引导 -->
            <div v-show="myStepNum == 3">
                <div class="gui-li-a">
                    <div class="zhiclass-le">
                        <div class="tiyan-title" style="margin-left:25px">职能中台<font>一</font><span>全面升级</span></div>
                        <div class="zhinengdesc" style="margin-left:25px">规范管控各职能部门权限和职责</div>
                        <div class="zhineng-anniu">
                            <div class="zhineng-anniu-a" @click="lunTaped(index)"
                                :class="myLunIndexed == index ? 'lunActive' : ''" v-for="item, index in zhinengData"
                                :key="index">
                                <img class="zhineng-anniu-a-le"
                                    :src="myLunIndexed == index ? item.topImged : item.topImg" />
                                <div class="zhineng-anniu-a-ri">{{ item.title }}</div>
                            </div>
                        </div>
                        <div class="tiyan-but-di" style="margin-top:10px;margin-left:25px;">
                            <div class="tiyan-but-di-le" @click="skip()">跳过</div>
                            <div class="tiyan-but-di-min" @click="lastStep()">上一步</div>
                            <div class="tiyan-but-di-ri" @click="nextStep()">下一步</div>
                        </div>
                    </div>
                    <div class="zhiclass-ri">
                        <div class="swiper-container swiper-main-containereds" ref="mySwipers">
                            <div class="content_container swiper-wrapper">
                                <div class="commonApply swiper-slide" v-for="item, index in zhinengData" :key="index">
                                    <img class="common_item_icon" style="width:100%;height:100%;" :src="item.img" />
                                </div>
                            </div>
                            <div class="pagination"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 结束页 -->
            <div v-show="myStepNum == 4" class="backimg">
                <div class="tiyan-title">全新版PC端<font>一</font><span>全面升级</span></div>
                <div class="gui-li-a">
                    <div class="gui-li-a-le">

                        <div class="tiyan-wenzi-a tiyan-wenzi-a-last" :class="indexNumOn == index ? 'tiyan-wenzi-a-active' : ''"
                            v-for="item, index in indexDataed" :key="index" @mouseenter="handleMouseEnter(index)"
                            @mouseleave="handleMouseEnd()">
                            <div class="tiyan-wenzi-a-le"><img
                                    :src="indexNumOn == index ? item.topImged : item.topImg" /> </div>
                            <div class="tiyan-wenzi-a-ri">
                                <div>{{ item.title }}</div>
                                <div>{{ item.desc }}</div>
                            </div>
                        </div>

                        <div class="tiyan-but-di" style="float:left;margin-top:40px;margin-left:30px; width:284px;">
                            <div class="tiyan-but-di-min" @click="lastStep()">上一步</div>
                            <div class="tiyan-but-di-ri" @click="skip()">我知道了</div>
                        </div>
                    </div>
                    <img :src="wei_a" class="gui-li-a-ri-wei" />
                </div>

            </div>




        </div>
    </div>
</template>
<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';
export default {
    props: {
        propsData: {
            type: Array,
        },
        propstagValue: {
            typeof: [Number, String]
        }
    },
    data() {
        return {
            indexNumOn: -1,
            myLunIndex: 0,
            myLunIndexed: 0,
            myStepNum: 1,
            cha: require("@/assets/img/version2.0/cha.png"),
            gui_a: require("@/assets/img/version2.0/gui-a.png"),
            wei_a: require("@/assets/img/version2.0/weiimg.png"),
            indexDataed: [
                {
                    topImg: require("@/assets/img/version2.0/ying-1.png"),
                    topImged: require("@/assets/img/version2.0/ying-ed.png"),
                    title: '应用工具',
                    desc: '技术团队管理应用列表、子系统、虚拟资产等',
                },
                {
                    topImg: require("@/assets/img/version2.0/xi-1.png"),
                    topImged: require("@/assets/img/version2.0/xi-ed.png"),
                    title: '系统设置',
                    desc: '管理系统角色/权限、部门/人员等基础信息    '
                },
                {
                    topImg: require("@/assets/img/version2.0/ge-1.png"),
                    topImged: require("@/assets/img/version2.0/ge-ed.png"),
                    title: '个人中心',
                    desc: '个人信息页，分账号信息、积分中心     '
                },
            ],
            indexData: [
                {
                    topImg: require("@/assets/img/version2.0/gong-1.png"),
                    topImged: require("@/assets/img/version2.0/gong-ed.png"),
                    title: '工作台',
                    desc: '账号视角下个人待办、图表统计以及常用功能等  ',
                },
                {
                    topImg: require("@/assets/img/version2.0/dai-1.png"),
                    topImged: require("@/assets/img/version2.0/dai-ed.png"),
                    title: '待办事项',
                    desc: '详尽待办记录，关键事项不遗漏 '
                },
                {
                    topImg: require("@/assets/img/version2.0/dong-1.png"),
                    topImged: require("@/assets/img/version2.0/dong-ed.png"),
                    title: '动态',
                    desc: '工作的【分享圈】，做好每一次计划/目标/任务    '
                },
            ],
            zhinengData: [
                {
                    topImg: require("@/assets/img/version2.0/ren-1.png"),
                    topImged: require("@/assets/img/version2.0/ren-1ed.png"),
                    img: require("@/assets/img/version2.0/ren-2.png"),
                    title: '人事',
                    status: 0
                },
                {
                    topImg: require("@/assets/img/version2.0/xing-1.png"),
                    topImged: require("@/assets/img/version2.0/xing-1ed.png"),
                    img: require("@/assets/img/version2.0/xing-2.png"),
                    title: '行政',
                    status: 0
                },
                {
                    topImg: require("@/assets/img/version2.0/cai-1.png"),
                    topImged: require("@/assets/img/version2.0/cai-1ed.png"),
                    img: require("@/assets/img/version2.0/cai-2.png"),
                    title: '财务',
                    status: 0
                },
                {
                    topImg: require("@/assets/img/version2.0/dang-1.png"),
                    topImged: require("@/assets/img/version2.0/dang-1ed.png"),
                    img: require("@/assets/img/version2.0/dang-2.png"),
                    title: '档案',
                    status: 0
                },
                {
                    topImg: require("@/assets/img/version2.0/sheng-1.png"),
                    topImged: require("@/assets/img/version2.0/sheng-1ed.png"),
                    img: require("@/assets/img/version2.0/sheng-2.png"),
                    title: '审批中心',
                    status: 0
                },
                {
                    topImg: require("@/assets/img/version2.0/ji-1.png"),
                    topImged: require("@/assets/img/version2.0/ji-1ed.png"),
                    img: require("@/assets/img/version2.0/ji-2.png"),
                    title: '技术',
                    status: 0
                },
            ],
            guideed: [
                {
                    topImg: require("@/assets/img/version2.0/nei-1.png"),
                    topImged: require("@/assets/img/version2.0/nei-1ed.png"),
                    img: require("@/assets/img/version2.0/nei-2.png"),
                    title: '内容中心',
                    desc: '内容生产协作、合理排片',
                    status: 0
                },
                {
                    topImg: require("@/assets/img/version2.0/tou-1.png"),
                    topImged: require("@/assets/img/version2.0/tou-1ed.png"),
                    img: require("@/assets/img/version2.0/tou-2.png"),
                    title: '投放中心',
                    desc: '电商业绩、投放消耗、直播管理',
                    status: 0
                },
                {
                    topImg: require("@/assets/img/version2.0/shang-1.png"),
                    topImged: require("@/assets/img/version2.0/shang-1ed.png"),
                    img: require("@/assets/img/version2.0/shang-2.png"),
                    title: '商务中心',
                    desc: '数据统计、管理账号/排期',
                    status: 0
                },
                {
                    topImg: require("@/assets/img/version2.0/yu-1.png"),
                    topImged: require("@/assets/img/version2.0/yu-1ed.png"),
                    img: require("@/assets/img/version2.0/yu-2.png"),
                    title: '预约中心',
                    desc: '约妆/约摄/约棚一步搞定',
                    status: 0
                },


            ],

        }
    },
    created() { },
    mounted() {
        let that = this
        this.$nextTick(() => {
            // 初始化轮播图
            let swiper = new Swiper('.swiper-main-containered', {
                paginationClickable: true,
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true,//修改swiper的父元素时，自动初始化swiper
                loop: false,
                initialSlide: that.myLunIndex,
                autoplay: {
                    delay: 2000, // 自动播放间隔时间，单位为毫秒
                    disableOnInteraction: false, // 用户操作后是否停止自动播放，默认为true，设为false则不会停止
                },

                pagination: {
                    el: '.pagination',
                    clickable: true,
                },
                on: {
                    slideChange: function (e) {
                        that.myLunIndex = swiper.activeIndex
                        console.log(that.myLunIndex)
                    },
                }
            });
        });
        this.$nextTick(() => {
            // 初始化轮播图
            let swiper = new Swiper('.swiper-main-containereds', {
                paginationClickable: true,
                observer: true, //修改swiper自己或子元素时，自动初始化swiper
                observeParents: true,//修改swiper的父元素时，自动初始化swiper
                loop: false,
                initialSlide: that.myLunIndexed,
                autoplay: {
                    delay: 2000, // 自动播放间隔时间，单位为毫秒
                    disableOnInteraction: false, // 用户操作后是否停止自动播放，默认为true，设为false则不会停止
                },
                pagination: {
                    el: '.pagination',
                    clickable: true,
                },
                on: {
                    slideChange: function (e) {
                        that.myLunIndexed = swiper.activeIndex
                    },
                }
            });
        });

    },
    methods: {
        handleMouseEnter(e) {
            this.indexNumOn = e
        },
        handleMouseEnd() {
            this.indexNumOn = -1
        },

        handleCarouselChange(newIndex, oldIndex) {
            this.myLunIndex = newIndex;
            this.myLunIndexed = newIndex;
        },
        lunTaped(e) {
            this.myLunIndexed = e
            this.$refs.mySwipers.swiper.slideTo(e)

        },
        lunTap(e) {
            this.myLunIndex = e
            this.$refs.mySwiper.swiper.slideTo(e)

        },
        //首页上一步
        lastStep(e) {
            if (e) {
                console.log(6)
                this.$emit('chanNewModel')
            } else {
                this.myStepNum = this.myStepNum - 1
            }
        },
        //下一步
        nextStep() {
            this.myStepNum = this.myStepNum + 1
            this.indexNumOn = -1
            this.myLunIndex =0
            this.myLunIndexed =0
            this.$refs.mySwiper.swiper.slideTo(0)
            this.$refs.mySwipers.swiper.slideTo(0)
        },

        skip() {
            this.$parent.notYet()
        },
    }
};
</script>
<style lang="scss" scoped>
.zhiclass-le {
    width: 416px;
    height: 552px;
    box-sizing: border-box;

    .zhineng-anniu {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 56px;
        margin-left: 20px;

    }

    .zhineng-anniu-a {
        width: 46%;
        display: flex;
        margin-bottom: 30px;
        cursor: pointer;

        .zhineng-anniu-a-le {
            width: 69px;
            height: 69px;
            position: relative;
            z-index: 1;

        }

        .zhineng-anniu-a-ri {
            width: 102px;
            height: 40px;
            margin-top: 14px;
            font-size: 18px;
            color: #5c6485;
            line-height: 40px;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            margin-left: -12px;
            text-indent: 18px;
        }
    }

    .lunActive .zhineng-anniu-a-ri {
        background: #4178FF;
        color: #fff;
    }

    .zhinengdesc {
        color: #717d9d;
        font-size: 18px;
        margin-top: 5px;
    }
}

.zhiclass-ri {
    width: 550px;
    height: 552px;
}

.yewuclass-le {
    width: 531px;

}

::v-deep .el-carousel__button {
    width: 7px;
    height: 7px;
    background-color: #AFB9D7;
    border-radius: 50%;
}

::v-deep .is-active .el-carousel__button {
    width: 22px;
    height: 5px;
    background: #3D7CF3;
    border-radius: 3px;

}

::v-deep .el-carousel__indicators {
    bottom: 25px;
}

.yewuclass-le-lunbo-img {
    width: 100%;
    height: 100%;
}

.yewuclass-ri {
    .yewuclass-ri-box {
        width: 436px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .yewuclass-ri-box-a {
            width: 50%;
            text-align: center;
            margin-top: 55px;
            cursor: pointer;

            .yewuclass-ri-a-img {
                width: 75px;
                height: 75px;
                display: block;
                margin: auto;
            }

            p {
                width: 74%;
                margin: 0 auto;
            }

            p:nth-child(2) {
                color: #9196ad;
                font-size: 18px;
                margin-top: 10px;
                height: 28px;
            }

            p:nth-child(3) {
                color: #b2b8c8;
                font-size: 14px;
                margin-top: 5px;
            }
        }

        .lunActive {
            p:nth-child(2) {
                color: #122865;
                font-weight: bold;
            }

            p:nth-child(3) {
                color: #717D9D;
            }
        }



    }
}

font {
    font-weight: 100;
    margin: 0 10px;
}

.gui-box {
    width: 100%;
    min-height: 100%;
    position: fixed;
    top: 0;
    background-color: rgba(0, 0, 0, .6);
    z-index: 9999;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .gui-box-li {
        width: 966px;
        height: 552px;
        margin: auto;
        background: #fff;
        border-radius: 20px;
        position: relative;
        // padding: 30px 30px;
        box-sizing: border-box;
        // overflow: hidden;
        z-index: 3000;

        .tiyan-title {
            font-weight: bold;
            font-size: 30px;
            color: #0b205adb;
            padding-top: 25px;
            margin-left: 25px;

            span {
                display: inline-block;
                /* 使背景只覆盖文字区域 */
                background-image: linear-gradient(to right, #579FF7, #2B5FF4);
                /* 定义渐变方向和颜色 */
                -webkit-background-clip: text;
                /* 仅在WebKit浏览器中应用 */
                color: transparent;
                /* 文字颜色设置为透明，以便显示背景渐变 */
            }
        }

        .tiyan-titleed {
            font-weight: bold;
            font-size: 30px;
            position: absolute;
            top: 25px;
            left: 25px;
            color: #0B205A;

            span {
                display: inline-block;
                /* 使背景只覆盖文字区域 */
                background-image: linear-gradient(to right, #579FF7, #2B5FF4);
                /* 定义渐变方向和颜色 */
                -webkit-background-clip: text;
                /* 仅在WebKit浏览器中应用 */
                color: transparent;
                /* 文字颜色设置为透明，以便显示背景渐变 */
            }
        }

        .gui-box-cha {
            width: 35px;
            height: 35px;
            position: absolute;
            top: -50px;
            right: -50px;
        }

        .gui-li-a {
            display: flex;
            justify-content: space-between;
            border-radius: 20px;
            overflow: hidden;
        }

        /* .gui-li-a-first{
            background-image: url('~@/assets/img/version2.0/gui-a.png');
            background-repeat: no-repeat;
            background-position: right bottom;
        } */


        .gui-li-a-le {
            width: 520px;
            margin-left: 20px;

            .tiyan-wenzi-a {
                margin-top: 20px;
                display: flex;
                padding: 15px;
                margin-left: 5px;
                width: 81%;

                .tiyan-wenzi-a-ri {
                    line-height: 31px;
                    font-size: 21px;
                    color: #333;
                    cursor: pointer;

                    div:nth-child(1) {
                        color: #122865;
                        font-size: 20px;
                        font-weight: bold;
                    }

                    div:nth-child(2) {
                        color: #717D9D;
                        font-size: 18px;
                        margin-top: 5px;
                    }
                }

                .tiyan-wenzi-a-le {
                    width: 30px;
                    height: 30px;
                    margin-right: 10px;
                    margin-top: 14px;
                    flex: none;

                    img {
                        width: 30px;
                        height: 30px;
                    }
                }
            }

            .tiyan-wenzi-a-last{
                width: 87% !important;
            }

            .tiyan-wenzi-a-active {
                background-color: rgb(255 255 255 / 64%);
                border-radius: 12px;
                box-shadow: 0px 6px 7px 0px rgba(196, 218, 250, 0.23);
            }
        }


        .gui-li-a-ri {
            position: absolute;
            right: -35px;
            bottom: -17px;
        }
    }

    .tiyan-but-di {
        width: 354px;
        display: flex;
        justify-content: space-between;
        margin-top: 50px;

        .tiyan-but-di-le {
            width: 50px;
            height: 41px;
            text-align: center;
            line-height: 41px;
            cursor: pointer;
            color: #6e7691;
            font-size: 16px;
        }

        .tiyan-but-di-min {
            width: 130px;
            height: 41px;
            border-radius: 30px;
            text-align: center;
            border: 1px solid #B7BAC7;
            line-height: 41px;
            cursor: pointer;
            color: #6e7691;
            font-size: 20px;
            box-sizing: border-box;
        }

        .tiyan-but-di-ri {
            width: 130px;
            height: 41px;
            border-radius: 21px;
            background: linear-gradient(257deg, #2C6CEF 0%, #70AAFF 100%);

            text-align: center;

            /* 定义渐变方向和颜色 */
            color: #fff;
            line-height: 41px;
            cursor: pointer;
            font-size: 20px;
        }
    }
}

.pagination {
    position: absolute;
    z-index: 20;
    bottom: 55px;
    width: 100%;
    text-align: center;
}

.swiper-pagination-switch {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: #555;
    margin: 0 5px;
    opacity: 0.8;
    border: 1px solid #fff;
    cursor: pointer;
}

.swiper-active-switch {
    background: #fff;
}

::v-deep .swiper-pagination-bullet {
    opacity: 1;
    background-color: #afb9d7;
}

::v-deep .swiper-pagination-bullet-active {
    width: 25px;
    border-radius: 5px;
    background-color: #3d7cf3;
}

.backimg {
    background-image: url("~@/assets/img/version2.0/backimg.png");
    width: 966px;
    height: 552px;
    margin: auto;
    background-size: 100% 100%;
    position: relative;
}

.gui-li-a-ri-wei {
    width: 482px;
    height: 434px;
    position: relative;
    right: 20px;
    bottom: 10px;
}
</style>