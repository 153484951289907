<template>
  <div class="empty_data"></div>
</template>

<script>
export default {
  name: 'EmptyData',
  data() {
    return {};
  },
  mounted() { },
  methods: {},
};
</script>

<style scoped lang="scss">
.empty_data {
  height: 100%;
  width: 100%;
  background-image: url('~@/assets/img/version2.0/empty_default.png');
  background-repeat: no-repeat;
  background-position: center calc(100% - 72px);
  position: absolute;
  left: 0;
  top: -28px;
}

.approve_empty {
  background-image: url('~@/assets/img/version2.0/approve_empty.png');
}

.common_empty {
  background-image: url('~@/assets/img/version2.0/common_empty.png');
  background-position: center;
}

.system_update_empty {
  background-image: url('~@/assets/img/version2.0/system_update_empty.png');
  height: 90%;
  top: 10%;
}

.task_empty {
  background-position: center;
  background-image: url('~@/assets/img/version2.0/task_empty.png');
}

.empty_date_quick_menu_search {
  background-position: center;
  height: 90%;
  top: 10%;
}
</style>
