import { addNum } from "@/api/index2.0"

export default {
  methods: {
    routeReplace(params) {
      let path = "/" + params.path
      if (path != this.$route.path) {
        if (params.menu_id != undefined && params.menu_id) {
          addNum({ menu_id: params.menu_id })
            .then((response) => {})
            .catch((error) => {})
        }

        this.$router.replace(path)
      }
    },
  },
}
