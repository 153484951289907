<template>
  <div id="vueDiv" class="main" :style="'height:' + windowHeight + 'px'" v-loading="versionLoading">
    <!-- 新版UI -->
    <indexVersion2 @replacePath="routeReplace"></indexVersion2>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import store from '@/store';
import indexVersion2 from './index_version2';
import toRoute from '@/mixins/toRoute';

import $ from 'jquery';

export default {
  components: {
    indexVersion2,
  },
  mixins: [toRoute],
  data() {
    return {
      windowHeight: document.documentElement.clientHeight,
      windowWidth: document.documentElement.clientWidth,
      newHeight: document.documentElement.clientHeight,
      menuType: 1, //左边选中的菜单
      topMenuSelectIndex: 1, //头部选中的部门下标+1
      leftContentMenuType: 1, //左边内容子菜单
      VUE_APP_API_BASE_URL: '',
      ENV: '',
      topMmenuList: [],
      leftMenuList: [],
      subMenuList: [],
      mobile_suffix: '',
      check_detect_interval: null,
      class_name: 'limit-width',
      sliderHidden: false,
      xtTipDialog: {
        visible: false,
      },
      openAndCloseImg: require("@/assets/img/content_manage/openAndClose.png"),
      assetsIdx: '', // 资产清单菜单的位置索引
      assetStatisticsIndex: '', // 行政资产 - 数据统计菜单的位置索引
      curVersion: '', //当前的版本
      versionLoading: false, //版本切换加载
    };
  },
  computed: {
    ...mapGetters([
      'menus',
      'avatar',
      'name',
      'first_route_path',
      'dynamic_auth',
    ]),
    key() {
      return this.$route.path;
    },
  },
  created() {
    //获取当前的版本
    this.curVersion = store.getters.version;
  },  
  watch: {
    '$root.jumpToClipMenu': {
      handler() {
        if (this.$root.jumpToClipMenu === true) {
          console.log('$root.jumpToClipMenu: ', this.$root.jumpToClipMenu)
          console.log('topMmenuList: ', this.topMmenuList)
          for (let i = 0; i < this.topMmenuList.length; i++) {
            if (this.topMmenuList[i]['name'] == 'contentManage/clipOrderSecondMenu' && this.topMmenuList[i].has_submenu === true) {
              for (let j = 0; j < this.topMmenuList[i].submenu.length; j++) {
                if (this.topMmenuList[i].submenu[j].name == 'contentWorkOrder/clip_confirm_order') {
                  this.selectContentMenu(j)
                  return
                }
              }
            }
          }
        }
        // console.log('total:' + this.$root.totalScore)
        // this.score = this.$root.totalScore
      },
      deep: true
    },
    $route(to, form) {
      let activeRoute = this.$route.query?.active_route ?? '';
      if (activeRoute == 'ada_assets') {
        this.selectContentMenu(this.assetsIdx);
      } else if (to.path == '/ada_assets/statistics') {
        this.selectContentMenu(this.assetStatisticsIndex);
      } else if (to.path.toLowerCase() == '/live/schedule') {
        let index = 0;
        if (Array.isArray(this.subMenuList) && this.subMenuList.length > 0) {
          for (let i = 0; i < this.subMenuList.length; i++) {
            if (this.subMenuList[i]['name'].toLowerCase() == 'live/schedule') {
              index = i;
              break;
            }
          }
        }

        this.selectContentMenu(index)
      }
    },
    subMenuList: {
      handler(subMenuData, oldSubMenuData) {
        // 记录后续需要进行跳转标记的菜单
        for (const subMenuIndex in subMenuData) {
          if (subMenuData[subMenuIndex]['name'] == 'ada_assets/assets') {
            this.assetsIdx = subMenuIndex;
          } else if (subMenuData[subMenuIndex]['name'] == 'ada_assets/statistics') {
            this.assetStatisticsIndex = subMenuIndex;
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    this.mobile_suffix = this.dynamic_auth.mobile_suffix;
    this.zoomWindow();
  },
  beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler); // resize自适应缩放，当窗口调整时，自动缩放
  },
  beforeDestroy() {
    /*
    if(this.check_detect_interval !== null) {
      clearInterval(this.check_detect_interval)
      console.log('clearInterval:' + this.check_detect_interval)
    }*/
    window.removeEventListener('resize', this.$_resizeHandler);
  },
  methods: {
    selectContentMenu: function (type) {
      //设置左边内容子菜单
      this.leftContentMenuType = this.subMenuList[type]['id'];
      // this.$router.replace('/' + this.subMenuList[type].name)
      this.route_replace(this.subMenuList[type].name);
    },
    zoomWindow() {
      // 缩放窗口
      let newRadio;
      if (this.windowWidth < 1600) {
        newRadio = 0.85;
        this.$root.isUseZoom = true;
      } else {
        newRadio = 1;
        this.$root.isUseZoom = false;
      }

      let newWidth = this.windowWidth / newRadio;
      let newHeight = this.windowHeight / newRadio;
      this.newHeight = newHeight;
      if (this.windowWidth < 1600) {
        this.class_name = 'small-width';
      } else {
        this.class_name = 'limit-width';
      }

      $('#app').css('width', newWidth);
      $('#app').css('height', newHeight);
      $('#vueDiv').css('width', newWidth);
      $('#vueDiv').css('height', newHeight);
      $('.rightMain').css('width', newWidth - 80);
      $('.rightMain').css('height', newHeight);
      $('.newRightMain').css('height', newHeight - 60);
      $('body').css('width', newWidth);
      $('body').css('height', newHeight);
      $('body').css('zoom', newRadio);
    },
    $_resizeHandler() {
      this.windowHeight = document.documentElement.clientHeight;
      this.windowWidth = document.documentElement.clientWidth;
      this.newHeight = document.documentElement.clientHeight;
      this.zoomWindow();
    },
  },
};
</script>

<style lang="scss">
@import '~@/assets/css/pc_new_ui/index_global.scss';
</style>
<style lang="scss" scoped>
@import '~@/assets/css/pc_new_ui/index.scss';
</style>
