<template>
  <div class="AppContainer">
    <!-- 顶部菜单 start -->
    <div class="topMain">
      <img class="logo" :src="logoImage" />
      <div class="menus">
        <el-dropdown class="top_menus_dropdown" v-for="(item, index) in topMenus" :key="'topMenu' + index"
          @command="selectTopFirstMenu" placement="bottom">
          <div :class="getTopMenuClass(topMenusIdx, index, item.name)" @click="onSelectTopMenu(index)">
            <!-- <img :src="topMenusIdx == index ? item.mobile_icon_active : item.pc_icon_active" /> -->
            <svg-icon class="myIndexIcon" :icon-class="item.new_icon"></svg-icon>
            <span>{{ item.title }}</span>
          </div>
          <el-dropdown-menu :append-to-body="false" slot="dropdown" v-show="item.has_submenu"
            :class="excludeRoute.includes(item.name) ? ' hiddenClass' : 'top_menu_popper'">
            <el-dropdown-item  v-for="(subMenu, subindex) in item.submenu" :command="index + ',' + subindex"
              :key="subindex">
              {{ subMenu.title }}
              <template><div v-if="subindex<item.submenu.length-1" class="gang-xian"></div></template>
              
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="top_Right">
        <el-popover placement="bottom" trigger="hover" popper-class="recently_used" @after-leave="quickMenuActive = 0">
          <div class="recently_used_body">
            <div class="left_menu">
              <div :class="quickMenuActive == quick_menu_index ? 'menu_item menu_item_active' : 'menu_item'"
                v-for="quick_menu, quick_menu_index in quick_menus" :key="'quick_menu_item_' + quick_menu_index"
                @click="quickMenuActive = quick_menu_index">
                <div class="menu_icon">
                  <!-- <img :src="quickMenuActive == quick_menu_index ? quick_menu.mobile_icon_active : quick_menu.mobile_icon" /> -->
                  <svg-icon class="myIndexIcon" :icon-class="quick_menu.new_icon"></svg-icon>
                </div>
                <div class="menu_name">{{ quick_menu.title }}</div>
              </div>
            </div>
            <div class="right_menu">
              <el-input class="menu_search_input" v-model="quick_menu_search" suffix-icon="el-icon-search"
                placeholder="输入你想要搜索的内容"></el-input>
              <div class="right_menu_container" v-if="filteredNodes.length > 0">
                <div :class="submenu_index == 0 ? 'right_menu_item' : 'right_menu_item right_menu_item_top'"
                  v-for="submenu, submenu_index in filteredNodes" :key="'right_menu_item_' + submenu_index">
                  <div class="item_header">{{ submenu.title }}</div>
                  <div class="item_list">
                    <div :class="last_menu_index % 5 == 0 ? 'item_list_menu' : 'item_list_menu item_list_menu_more'"
                      v-for="last_menu, last_menu_index in submenu.submenu" :key="'item_list_menu_' + last_menu_index"
                      @click="toRoute(last_menu)">
                      <div class="item_list_menu_name">{{ last_menu.title }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <EmptyData class="empty_date_quick_menu_search" v-else></EmptyData>
            </div>
          </div>
          <div class="lately topRightBtn" slot="reference">
            <img :src="latelyIcon" />
            <span>快捷入口</span>
          </div>
        </el-popover>

        <div v-if="top_search_show" class="search_input">
          <el-autocomplete :popper-append-to-body="false" popper-class="top_search_autocomplete" :fetch-suggestions="topQuerySearch"
            ref="topSearchInput" class="top_search_input" v-model="top_search" suffix-icon="el-icon-search"
            placeholder="请输入想要搜索的内容" @select="handleTopQuerySelect"></el-autocomplete>
        </div>
        <img v-if="!top_search_show" class="searchTopRightIcon topRightIcon" :src="searchIcon" @click="topMenuSearch" />
        <div class="midLine"></div>
        <div class="userName">{{ name }}</div>
        <div class="userIcon" @click="toPersonCenter"><img :src="avatar" width="48" height="48"/></div>
      </div>
    </div><!-- 顶部菜单 end -->
    <div class="bottom">
      <!-- 左侧菜单 start -->
      <div :class="leftMenuShow ? 'newLeftMain newLeftMain-ed' : 'newLeftMain newLeftMain-ed newLeftMain-collapse'">
        <div class="expand_sub_menu" v-show="leftMenuShow">
          <div class="subMenuItem" v-for="(subMenuDt, subIdx) in subMenus" :key="subMenuDt.id">
            <div class="topTitle" @click="selectFirstMenu(subIdx, subMenuDt.submenu)">{{ subMenuDt.title }}</div>
            <el-tree class="submenu_tree" icon-class="el-icon-arrow-right" node-key="name" ref="tree"
              :data="subMenuDt.submenu" :props="leftTreeProps" @node-click="handleNodeClick"
              :current-node-key="currentKey" :render-content="renderContent"
              v-if="firstMenusIdx == subIdx && subMenuDt.has_submenu"> </el-tree>
          </div>
        </div>
      </div> <!-- 左侧菜单 end -->
      <!-- 页面内容 start-->
      <div class="newRightMain">
        <div class="collapse_expand" @click="leftMenuShow = !leftMenuShow">
          <i class="el-icon-arrow-left collapse_expand_icon" v-if="leftMenuShow"></i>
          <i class="el-icon-arrow-right collapse_expand_icon" v-if="!leftMenuShow"></i>
        </div>
        <div class="contentMain">
          <router-view :key="key" />
        </div>
      </div><!-- 页面内容 end-->
    </div>
    <!-- 新手引导 -->
    <newGuide v-if="newModelBox" @chanNewModel="chanNewModel"></newGuide>
    <!-- 新用户体验弹窗 -->
    <div class="newModelclass" v-if="newModel">
      <div class="newModelclass-li">
        <img @click="newModelBox = false, newModel = false" class="newModelclass-cha" :src="cha" />
        <div class="tiyan-title">欢迎使用全新版PC端<font>一</font><span>美哒OA</span> <img :src="verimg" /> </div>
        <div class="tiyan-wenzi">
          <div class="tiyan-wenzi-a">
            <div class="tiyan-wenzi-a-le"></div>
            <div class="tiyan-wenzi-a-ri">调整整体UI框架</div>
          </div>
          <div class="tiyan-wenzi-a">
            <div class="tiyan-wenzi-a-le"></div>
            <div class="tiyan-wenzi-a-ri">将美哒核心业务模块<b>抽象化、模块化，分业务中台、职能中台</b>等供各业务部门使用</div>
          </div>
          <div class="tiyan-wenzi-a">
            <div class="tiyan-wenzi-a-le"></div>
            <div class="tiyan-wenzi-a-ri">新增【首页】、【应用工具】、【个人中心】等模块，完善美哒OA-PC端的框架</div>
          </div>
          <div class="tiyan-wenzi-a">
            <div class="tiyan-wenzi-a-le"></div>
            <div class="tiyan-wenzi-a-ri">优化【系统设置】模块，方便技术团队维护系统基础数据</div>
          </div>
        </div>
        <div class="tiyan-but-di">
          <div class="tiyan-but-di-le" @click="newModelBox = true, newModel = false">了解更多</div>
          <div class="tiyan-but-di-ri" @click="notYet ">立即体验</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import newGuide from './components/newGuide.vue';
import EmptyData from '../views/workTable/components/EmptyData';
import store from '@/store';
import $ from 'jquery';

export default {
  name: 'index_version2',
  components: {
    newGuide,
    EmptyData,
  },
  data() {
    return {
      cha: require('@/assets/img/version2.0/cha.png'),
      newModel: false,
      newModelBox: false,
      leftMenuShow: true,
      excludeRoute: ['pc_home/index', 'pc_geren/index'],
      topExcludeRoute: ['pc_geren/index'],
      quickMenuActive: 0,
      quick_menu_search: '',
      top_search: null,
      top_search_show: false,
      windowHeight: document.documentElement.clientHeight,
      windowWidth: document.documentElement.clientWidth,
      topMenus: [], //总菜单
      subMenus: [], //次级左侧菜单
      leftTreeProps: {
        children: 'submenu',
        label: 'title',
      },
      topMenusIdx: 0, //顶部选中菜单栏
      firstMenusIdx: 0, //次级菜单下标
      curSelectId: 0, //当前选择的菜单栏id
      menuIdxInfoDataList: {}, //界面菜单索引信息
      currentKey: '', //当前的路由
      lastCurrentKey: '', //上一次的路由
      lastCurrent: '', //上次选中的节点
      curVersion: '', //当前系统版本(defalut:新 normal:旧)
      topBgImage: require('@/assets/img/version2.0/topBg.png'),
      logoImage: require('@/assets/img/version2.0/logo.png'),
      switchoverIcon: require('@/assets/img/version2.0/switchoverIcon.png'),
      latelyIcon: require('@/assets/img/version2.0/latelyIcon.png'),
      searchIcon: require('@/assets/img/version2.0/searchIcon.png'),
      modelIcon: require('@/assets/img/version2.0/modelIcon.png'),
      questionIcon: require('@/assets/img/version2.0/questionIcon.png'),
      verimg: require('@/assets/img/version2.0/ver-new.png'),
      applications: [],
    };
  },
  computed: {
    ...mapGetters([
      'menus',
      'quick_menus',
      'avatar',
      'name',
      'first_route_path',
      'dynamic_auth',
      'search_applications',
      'pc_user_route',
      'show_step',
      'pc_show_step'
    ]),
    key() {
      return this.$route.path;
    },
    filteredNodes() {
      if (!Array.isArray(this.quick_menus) || this.quick_menus.length <= 0) {
        return [];
      }

      const query = this.quick_menu_search.toLowerCase().trim();
      if (!query) {
        return this.quick_menus[this.quickMenuActive].submenu;
      } else {
        let results = this.filterTree(
          this.quick_menus[this.quickMenuActive].submenu
        );

        return results.filter((node) => this.hasChildren(node));
      }
    },
  },
  watch: {
    $route(to, form) {
      /**
       * 菜单选中状态处理，将当前页面的菜单设置为active，并去除原有active状态
       */
      this.currentKey = to.path.slice(1);
      let idxArr = this.menuIdxInfoDataList[this.currentKey];
      this.showTargetMenu(idxArr); //将要打开的菜单增加active状态
    },
  },
  created() {
    this.newModel =  store.getters.pc_show_step;
    //获取当前的版本
    this.curVersion = store.getters.version;
    //设置界面初始路径
    this.currentKey = this.$route.path.slice(1);
    this.lastCurrentKey = this.currentKey;
    this.topMenus = this.menus;
    this.subMenus = this.topMenus[this.topMenusIdx].submenu;
    //记录一份目录菜单索引信息
    this.getMenuIdxData(this.topMenus);

    for (let index = 0; index < this.search_applications.length; index++) {
      const element = this.search_applications[index];
      this.applications.push({
        id: element.id,
        value: element.title,
        name: element.name,
        is_url: element.is_url,
      });
    }
  },
  mounted() {
    document.addEventListener('click', this.onClickOutside);

    //用于菜单栏定位
    this.$nextTick(function () {
      let idxArr = this.menuIdxInfoDataList[this.currentKey];
      this.showTargetMenu(idxArr);
    });
  },
  destroyed() {
    document.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    fetchTreeData() {
      this.$refs.tree.setCurrentKey(this.curren);
    },

    chanNewModel() {
      this.newModel = true;
      this.newModelBox = false;
    },
    /*
     *关闭新人引导弹窗
     */
    notYet() {
      this.newModel = false;
      this.newModelBox = false;
      this.$store.commit('user/SET_PC_SHOW_STEP', false);
    },
    getTopMenuClass(topMenusIdx, index, route) {
      let topClass = '';
      if (topMenusIdx == index) {
        topClass = 'select topMenusItem el-dropdown-link';
      } else {
        topClass = 'normal topMenusItem el-dropdown-link';
      }

      if (this.topExcludeRoute.includes(route)) {
        topClass = topClass + ' hiddenTopMenu';
      }

      return topClass;
    },
    onClickOutside(event) {
      let target = event.target;
      let popperClass = $(target).attr('popperclass');
      if (
        target.classList.contains('searchTopRightIcon') ||
        target.classList.contains('top_search_autocomplete')
      ) {
        return;
      }

      if (
        popperClass != undefined &&
        popperClass == 'top_search_autocomplete'
      ) {
        return;
      }

      let poperBox = document.getElementsByClassName('top_search_autocomplete');
      if (poperBox.length > 0 && poperBox[0].contains(target)) {
        return;
      }

      this.top_search_show = false;
    },
    topQuerySearch(queryString, cb) {
      let results = queryString
        ? this.applications.filter(this.createFilter(queryString))
        : this.applications;

      cb(results);
    },
    createFilter(queryString) {
      const query = queryString.toLowerCase().trim();

      return (app) => {
        return app.value.toLowerCase().includes(query);
      };
    },
    handleTopQuerySelect(item) {
      this.toRoute(item);

      this.top_search = null;
      this.top_search_show = false;
    },
    topMenuSearch() {
      this.top_search_show = true;
      this.$nextTick(function () {
        this.$refs['topSearchInput'].focus();
      });
    },
    toRoute(menu) {
      if (this.currentKey == menu.name) {
        return;
      }

      //跳转到指定的页面
      this.$emit('replacePath', {
        path: menu.name,
        menu_id: menu.id,
      });
    },
    filterTree(nodes) {
      const query = this.quick_menu_search.toLowerCase().trim();
      return nodes
        .map((node) => ({
          ...node,
          submenu: this.filterTree(node.submenu || []),
        }))
        .filter(
          (node) =>
            node.title.toLowerCase().includes(query) || this.hasChildren(node)
        );
    },
    hasChildren(node) {
      return Array.isArray(node.submenu) && node.submenu.length > 0;
    },
    getMenuIdxData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].has_submenu) {
          this.getSecondMenuIdx(data[i].submenu, [i]);
        }
      }
    },
    getSecondMenuIdx(list, path) {
      for (let i = 0; i < list.length; i++) {
        let currentPath = path.concat(i);
        if (list[i].has_submenu) {
          this.getSecondMenuIdx(list[i].submenu, currentPath);
        } else {
          this.menuIdxInfoDataList[list[i].name] = currentPath;
        }
      }
    },
    handleNodeClick(data, node) {
      let beforeNode = this.$refs['tree'][0].getNode(this.lastCurrentKey);
      //console.error('beforeNode==>', beforeNode ? beforeNode.data.title : null)
      if (beforeNode) {
        this.resetParentNodeCurrent(beforeNode)
        if (beforeNode.parent) {
          this.resetParentNodeCurrent(beforeNode.parent)

          //重置上次按钮的展开状态
          if (beforeNode.data.name != node.data.name) {
            if (node.parent && node.parent.data.name != beforeNode.parent.data.name) {
              beforeNode.parent.expanded = false
            }
          }
        }
        if (beforeNode.childNodes) {
          this.resetParentNodeCurrent(beforeNode.childNodes[0]);
        }
      }
      // 如果点击的是子节点，则手动设置其父节点的当前状态
      if (node.isLeaf) {
        this.currentKey = data.name;
        this.lastCurrentKey = this.currentKey;
        //将上次选中的样式改为未选中
        if (this.lastCurrent.id != node.id) {
          this.resetParentNodeCurrent(this.lastCurrent);
          this.resetParentNodeCurrent(this.lastCurrent.parent);
        }
        if (node) {
          this.setNodeCurrent(node);
          this.setNodeCurrent(node.parent);
          //跳转到指定的页面
          this.$emit('replacePath', { path: this.currentKey, menu_id: data.id, });
        }
      } else {
        this.currentKey = data.submenu[0].name;
        this.lastCurrentKey = this.currentKey;
        //跳转到指定的页面
        this.$emit('replacePath', { path: this.currentKey, menu_id: data.submenu[0].id, });
        if (node.childNodes) {
          this.setNodeCurrent(node.childNodes[0]);
          node.expanded = true;
        }
        this.setNodeCurrent(node);
      }
    },
    setNodeCurrent(node) {
      if (node) {
        node.isCurrent = true;
      }
    },
    resetParentNodeCurrent(node) {
      if (node) {
        node.isCurrent = false;
      }
    },
    renderContent(h, { node, data, store }) {
      if (data.name == this.currentKey) {
        this.lastCurrent = node;
      }

      if (data.new_icon && node.level == 1) {
        let svg_class = 'svg-icon main-left-menu-icon'
        if (node.isCurrent) {
          svg_class = svg_class + ' main-left-active'
        }

        return h(
          'span',
          {
            class: 'menuClass',
          },
          [
            // 展示图片
            h('svg-icon', {
              attrs: {
                'icon-class': data.new_icon, // 使用变量作为图片链接
                "class": svg_class,
                // alt: 'Image',
                width: 16, // 图片宽度
                height: 16, // 图片高度
              },
            }),
            // 展示节点标签
            h('span', null, data.title),
          ]
        );
      } else {
        return <span class="tapClass">{data.title}</span>;
      }
    },
    openXtTipDialog() {
      this.xtTipDialog.visible = true;
    },
    hiddenSlideBar() {
      this.sliderHidden = !this.sliderHidden;
    },
    onSelectTopMenu(idx) {
      let dtStr = idx + ',' + 0;
      this.selectTopFirstMenu(dtStr);
    },
    selectTopFirstMenu(commandDt) {
      let dtStr = commandDt.split(',');
      let idx = Number(dtStr[0]);
      let subIdx = Number(dtStr[1]);
      this.topMenusIdx = idx;
      this.subMenus = this.topMenus[this.topMenusIdx].submenu;
      this.firstMenusIdx = subIdx;
      if (this.subMenus[this.firstMenusIdx].submenu[0].has_submenu) {
        this.currentKey =
          this.subMenus[this.firstMenusIdx].submenu[0].submenu[0].name;
      } else {
        this.currentKey = this.subMenus[this.firstMenusIdx].submenu[0].name;
      }
      this.$nextTick(() => {
        let curNode = this.$refs['tree'][0].getNode(this.currentKey);
        if (curNode.parent) {
          curNode.parent.expanded = true;
        }
        this.handleNodeClick(curNode.data, curNode);
      });
    },
    //打开指定的次级菜单
    selectFirstMenu(idx, menus) {
      this.firstMenusIdx = idx;

      if (Array.isArray(menus) && menus.length > 0) {
        let lastFirstMenu = this.findLastLevelFirstChild(menus[0])
        this.currentKey = lastFirstMenu.name

        this.$nextTick(function () {
          let currentNode = this.$refs['tree'][0].getNode(this.currentKey);
          if (currentNode.parent) {
            currentNode.parent.expanded = true;
          }

          this.handleNodeClick(currentNode.data, currentNode);
        })
      }
    },
    // 递归查找最后一级子节点的第一个分类
    findLastLevelFirstChild(node) {
      if (this.hasChildren(node)) {
        return this.findLastLevelFirstChild(node.submenu[0]);
      } else {
        return node;
      }
    },
    //菜单栏定位
    showTargetMenu(idxArr) {
      if (!idxArr || idxArr == undefined || idxArr.length <= 0) {
        return;
      }

      this.topMenusIdx = idxArr[0];
      this.subMenus = this.topMenus[this.topMenusIdx].submenu;
      this.firstMenusIdx = idxArr[1];
      let secondIdx = idxArr[2];
      if (secondIdx) {
        this.currentKey =
          this.subMenus[this.firstMenusIdx].submenu[secondIdx].name;
      }

      let thirdIdx = idxArr[3];
      if (thirdIdx) {
        this.currentKey =
          this.subMenus[this.firstMenusIdx].submenu[secondIdx].submenu[
            thirdIdx
          ].name;
      }
      this.$nextTick(() => {
        let curNode = this.$refs['tree'][0].getNode(this.currentKey);
        if (curNode.parent) {
          curNode.parent.expanded = true;
        }
        this.handleNodeClick(curNode.data, curNode);
      });
    },
    toPersonCenter() {
      this.toRoute({
        name: this.pc_user_route.path,
        id: this.pc_user_route.id,
      });
    },
  },
};
</script>
<style lang="scss">
@import '~@/assets/css/pc_new_ui/index_version2_global.scss';
</style>
<style lang="scss" scoped>
@import '~@/assets/css/pc_new_ui/index_version2.scss';
@import '~@/assets/css/pc_new_ui/recently_used.scss';

.hiddenTopMenu {
  display: none;
}

::v-deep .main-left-menu-icon {
  width: 16px !important;
  height: 16px !important;
  color: #595959;
  fill: currentColor;
  overflow: hidden;
}

::v-deep .main-left-iconed {
  color: #2d6aff;
}

.main-left-icon.main-left-iconed {
  color: #125cfb;
}

.main-left-menu-icon.main-left-active {
  color: #125cfb;
}
.top_menu_popper .el-dropdown-menu__item{
  padding: 0 15px;
  .gang-xian{
       width: 90%;
       border-bottom: 1px solid #f3f3f4;
       margin:auto;
       position: relative;
       top: -2px;
  }
}
.el-dropdown-menu:hover{
  .gang-xian{
    border-bottom: 1px solid transparent;
    // border: 0;
  }
}
.top_menu_popper .el-dropdown-menu__item:hover{
  background-color: #eff4ff;

}
</style>
